
@font-face {
  font-family: "poppins400";
  src: local("poppins400"),
   url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
 }

 @font-face {
  font-family: "poppins700";
  src: local("poppins700"),
   url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
 }

 @font-face {
  font-family: "poppins500";
  src: local("poppins500"),
   url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
 }

 @font-face {
  font-family: "poppins600";
  src: local("poppins600"),
   url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 500;
 }
 @font-face {
  font-family: "poppinsItalic300";
  src: local("poppinsItalic300"),
   url("./assets/fonts/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 500;
 }

body {
  margin: 0;
  
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
